import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { Card } from '../Cards';
import { Grid, GridItem, GridRow, getCardGridColspan } from '../Grid';
import { LiquidButton } from '../LiquidButton';
import { SortAndFilter } from '../SortAndFilter';
import { Title } from '../Typography';
import { Container } from './styles';

export interface IProductGrid {
  id: string;
  heading: string;
  products?: any[];
  initialCount?: number;
  pageCount?: number;
  labels?: Record<string, string>;
  currentSort: string;
  sortOptions?: { label: string; value: string }[];
  onShowFilters?: () => void;
  onSortProducts?: (arg0: string) => void;
  ctaColor?: string;
  cardsColor?: string;
  currentFiltersCount?: number;
  trackingEvent?: (data?: any, position?: number) => void;
}

const MOBILE_CARD_HEIGHT = 438;
const DESKTOP_CARD_HEIGHT = 360;
export const ProductGrid = ({
  id,
  heading,
  products = [],
  initialCount = 16,
  pageCount = 8,
  labels = {
    results: 'results',
    resultsSeen: '%0 of %1 results seen',
    sortBy: 'Sort By',
    filters: 'Filters',
    moreResults: 'Show more',
    sortNewest: 'Newest',
    sortAlpha: 'Order (A-Z)',
  },
  currentSort,
  sortOptions,
  onShowFilters = () => null,
  onSortProducts = () => null,
  ctaColor,
  currentFiltersCount,
  trackingEvent,
}: IProductGrid) => {
  const [visibleCount, setVisibleCount] = useState<number>(() =>
    products.length > initialCount ? initialCount : products.length,
  );
  const [cardHeight, setCardHeight] = useState<number>(MOBILE_CARD_HEIGHT);
  const onShowMoreItems = () =>
    setVisibleCount(Math.min(products.length, visibleCount + pageCount));

  useEffect(() => {
    setCardHeight(window.innerWidth <= 320 ? MOBILE_CARD_HEIGHT : DESKTOP_CARD_HEIGHT);
  }, []);

  useEffect(() => {
    if (visibleCount > products.length) setVisibleCount(products.length);
    if (products.length > initialCount && visibleCount < initialCount)
      setVisibleCount(initialCount);
  }, [products]);

  return (
    <Container className="max-w-none p-0" data-test={id}>
      <div className="flex flex-col items-center justify-center md:justify-between md:flex-row px-small md:pb-10 pb-0 2xl:px-medium 3xl:px-xlarge w-full">
        <div className="flex flex-grow justify-center w-full md:mr-14 md:justify-start">
          <Title tag="h2" type="sm">
            {heading}
          </Title>
        </div>
        <div className="flex w-full mt-5 md:mt-0">
          <SortAndFilter
            id={`${id}_sort-controls`}
            labels={labels}
            options={sortOptions}
            selectedSort={currentSort}
            currentCount={products?.length || 0}
            onSort={onSortProducts}
            onFilter={onShowFilters}
            currentFiltersCount={currentFiltersCount}
          />
        </div>
      </div>
      <Grid>
        <GridRow columns={16} tabletBreakpoint>
          {products?.slice(0, visibleCount).map((p: any, index: number) => (
            <GridItem
              key={p.id}
              colSpan={getCardGridColspan(visibleCount, index)}
              className={getCardGridColspan(visibleCount, index) === 8 ? 'product-grid-big' : ''}
            >
              <Card
                data-test="product-grid-card"
                data={p}
                copyDictionary={labels}
                showInfo
                cardVariation="product"
                link={p.link}
                cardType="mediumSmall"
                height={`${cardHeight}px`}
                trackingEvent={trackingEvent}
                position={index + 1}
              />
            </GridItem>
          ))}
        </GridRow>
      </Grid>

      <div className="text-center" data-test={`${id}_show-more-controls`}>
        {visibleCount < products.length && (
          <button type="button" onClick={onShowMoreItems} data-test={`${id}_show-more-button`}>
            <span className="sr-only">{labels?.moreResults}</span>
            <LiquidButton
              text={labels?.moreResults}
              height={50}
              width={200}
              color={ctaColor || COLORS.primary}
              textColor={COLORS.white}
            />
          </button>
        )}
      </div>
    </Container>
  );
};

ProductGrid.displayName = 'ProductGrid';

export default ProductGrid;
