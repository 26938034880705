/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  BodyScrollOptions,
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { FiltersIcon } from '@icons/FiltersIcon';
import React, { createRef, useEffect, useState } from 'react';
import { CloseIcon } from '@icons/Close';
import { CheckMarkIcon } from '@icons/CheckMarkIcon';
import { SortByIcon } from '@icons/SortByIcon';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Title } from '../Typography';
import {
  ButtonsWrapper,
  CountText,
  FilterButton,
  SortFilterContainer,
  SortIndicator,
  SortOption,
  SortOptionContainer,
} from './styles';

export interface IFilterMenuProps {
  id?: string;
  className?: string;
  labels?: Record<string, string>;
  options?: { label: string; value: string }[];
  selectedSort?: string;
  currentCount: number;
  currentFiltersCount?: number;
  showFilter?: boolean;
  onSort?: (arg0: string) => void;
  onFilter?: () => void;
}

const SortAndFilter: React.FC<IFilterMenuProps> = ({
  className = '',
  labels = {
    filters: 'Filters',
    sortBy: 'Sort By',
    results: 'results',
  },
  options,
  selectedSort,
  currentCount,
  currentFiltersCount,
  showFilter = true,
  onSort = () => null,
  onFilter = () => null,
}: IFilterMenuProps) => {
  const [sortMenuVisible, setSortMenuVisible] = useState<boolean>(false);
  const dropdownMenuRef = createRef<HTMLDivElement>();

  const bodyScrollOptions: BodyScrollOptions = {
    reserveScrollBarGap: true,
  };
  useEffect(() => {
    if (dropdownMenuRef.current && window.innerWidth <= 768) {
      // Lock scroll on Mobile only
      if (sortMenuVisible) {
        disableBodyScroll(dropdownMenuRef.current, bodyScrollOptions);
      } else {
        enableBodyScroll(dropdownMenuRef.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [sortMenuVisible]);

  useOnClickOutside(dropdownMenuRef, () => setSortMenuVisible(false));
  return (
    <SortFilterContainer className={`${className} relative z-20`} ref={dropdownMenuRef}>
      {currentCount !== undefined && (
        <CountText tag="span" type="md">{`${currentCount} ${labels?.results}`}</CountText>
      )}

      <ButtonsWrapper>
        {options?.length ? (
          <FilterButton
            type="button"
            className="md:ml-6"
            $active={sortMenuVisible}
            onClick={() => {
              if (window && window.innerWidth < 768) window.scrollTo(0, 0);
              setSortMenuVisible(!sortMenuVisible);
            }}
          >
            <span className="hidden md:inline-block relative top-0.5">
              <SortByIcon />
            </span>
            <span className="md:ml-2">{labels?.sortBy}</span>
          </FilterButton>
        ) : null}
        {showFilter && (
          <FilterButton
            type="button"
            className={`${options?.length ? 'ml-2.5' : ''} md:ml-8`}
            onClick={() => {
              onFilter();
              setSortMenuVisible(false);
            }}
          >
            <span className="hidden md:inline-block relative top-0.5">
              <FiltersIcon />
            </span>
            {currentFiltersCount ? (
              <span className="md:ml-2">
                {labels?.filters} <span className="md:hidden">{`(${currentFiltersCount})`}</span>
              </span>
            ) : (
              <span className="md:ml-2">{labels?.filters}</span>
            )}
          </FilterButton>
        )}
      </ButtonsWrapper>
      {sortMenuVisible && (
        <SortOptionContainer>
          <div className="p-5 flex items-center justify-between md:hidden">
            <Title tag="span" type="xxxs">
              {labels?.sortBy}
            </Title>
            <div onClick={() => setSortMenuVisible(false)}>
              <CloseIcon size={24} />
            </div>
          </div>
          <ul className="px-5 m-0 md:py-4 md:px-0">
            {options?.map((opt) => (
              <li key={opt.value}>
                <SortOption
                  type="button"
                  $selected={opt.value === selectedSort}
                  onClick={() => {
                    onSort(opt.value);
                    setSortMenuVisible(false);
                  }}
                >
                  <SortIndicator>
                    {opt.value === selectedSort ? (
                      <CheckMarkIcon />
                    ) : (
                      <div className="w-5 h-5 border-1 opacity-20 md:hidden" />
                    )}
                  </SortIndicator>
                  {opt.label}
                </SortOption>
              </li>
            ))}
          </ul>
        </SortOptionContainer>
      )}
    </SortFilterContainer>
  );
};

export { SortAndFilter };
