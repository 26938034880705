import tw from 'tailwind-styled-components';
import { Text as Paragraph } from '../Typography';

export const SortFilterContainer = tw.div`
    flex
    flex-row
    flex-wrap
    items-center
    justify-center
    md:flex-nowrap
    md:justify-end
    w-full
`;

export const CountText = tw(Paragraph)`
    order-4
    md:order-1
    w-full
    md:w-auto
    text-center
    md:text-left
    pt-2.5
    md:pt-0
    whitespace-nowrap
`;

export const ButtonsWrapper = tw.div`
    order-2
    w-full
    md:w-auto
    text-center
    md:text-left
    flex
    flex-nowrap
    justify-center
`;

export const FilterButton = tw.button<{ $active?: boolean }>`
    whitespace-nowrap
    text-xs
    border
    rounded-full
    tracking-xl
    py-2
    px-3.5
    leading-4
    uppercase
    md:tracking-normal
    md:font-primary
    md:text-2xl
    md:leading-8
    md:border-0
    md:rounded-none
    md:font-medium
    md:p-0
    md:normal-case
    ${({ $active }) => ($active ? 'md:border-b-2' : 'md:border-b-2 md:border-transparent')}
`;

export const SortOptionContainer = tw.div`
    fixed
    top-headerM
    overflow-x-hidden
    right-0
    left-0
    bg-white
    z-50
    h-full
    md:z-10
    md:top-full
    md:absolute
    md:left-auto
    md:w-300
    md:h-auto
`;

export const SortIndicator = tw.div`
    w-5
    mr-5
    md:w-16
    md:w-6
    md:mr-0
`;

export const SortOption = tw.button<{ $selected: boolean }>`
    w-full
    py-2
    text-base
    flex
    flex-row
    content-start
    items-center
    md:hover:bg-light-brown
    md:p-2
    ${({ $selected }) => ($selected ? 'font-medium' : 'font-normal')}
`;
