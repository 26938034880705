import styled from 'styled-components';

export const Container = styled.section`
  @media (min-width: 1025px) {
    .product-grid-big {
      .svg-product {
        bottom: calc(100% + 65px);
      }
      .card-link-component {
        &:hover {
          .svg-product {
            bottom: calc(100% + 68px);
          }
        }
      }
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    .product-grid-big {
      .svg-product {
        bottom: calc(100% + 65px);
      }
    }
  }
`;
